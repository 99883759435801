.blogs-main {
  width: 100vw;
}

.blog-heading-div {
  display: flex;
}

.blog-heading-div > * {
  flex: 1;
}

.blog-heading-img-div {
  align-items: center;
  justify-content: center;
}

.blog-heading-text-div {
  text-align: center;
}

.blog-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.blog-heading-text {
  font-size: 35px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 40px;
}

.blog-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.blogsite-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog-image {
  margin-left: 22%;
}

@media (max-width: 1380px) {
  .blog-heading-text {
    font-size: 30px;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .blog-heading-text {
    font-size: 25px;
    margin-top: 0px;
  }

  .blog-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }

  .blog-image {
  }
}
