.cls-1 {
  fill: none;
}
.cls-2 {
  clip-path: url(#clip-path);
}
.cls-3 {
  fill: #f0f8fe;
}
.cls-4,
.cls-6 {
  fill: #fff;
}
.cls-4 {
  opacity: 0.66;
}
.cls-5 {
  fill: #bcbfc3;
}
.cls-7 {
  fill: #bbbdbf;
}
.cls-8 {
  fill: #f9f9f9;
}
.cls-9 {
  fill: #f0f2f5;
}
.cls-10 {
  fill: #f2f2f2;
}
.cls-11 {
  fill: #62b4f8;
}
.cls-12 {
  fill: #f9fbfc;
}
.cls-13 {
  fill: #e5e5e5;
}
.cls-14 {
  fill: #3a3f47;
}
.cls-15 {
  fill: #eea886;
}
.cls-16 {
  fill: #eebea7;
}
.cls-17 {
  fill: #0b4870;
}
.cls-18 {
  fill: #ffbe55;
}
.cls-19 {
  fill: #d3d3d3;
}
.cls-20 {
  fill: #5f8cc9;
}
.cls-21 {
  fill: #fcd2b1;
}
.cls-22 {
  fill: #2d2d2d;
}
.cls-23 {
  fill: #2a94f4;
}
.cls-24 {
  fill: #ffe6ae;
}
.cls-25 {
  fill: #f7d98f;
}
.cls-26 {
  fill: #606bf1;
}
.cls-27 {
  fill: #fbd15b;
}
.cls-28 {
  fill: #ff8578;
}
